import { Portal } from "@radix-ui/react-tooltip";
import { ReactNode } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

export function TooltipWrapper({
  children,
  disabled,
  tooltip,
  wrapperClassName,
  wrapperStyle,
}: {
  children: ReactNode;
  disabled?: boolean;
  tooltip: ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}) {
  if (disabled) {
    return (
      <div className={wrapperClassName} style={wrapperStyle}>
        {children}
      </div>
    );
  }
  return (
    <TooltipProvider>
      <Tooltip disableHoverableContent={disabled}>
        <TooltipTrigger asChild>
          <div className={wrapperClassName} style={wrapperStyle}>
            {children}
          </div>
        </TooltipTrigger>
        <Portal>
          <TooltipContent side="right" className="max-w-64 text-xs font-medium">
            {tooltip}
          </TooltipContent>
        </Portal>
      </Tooltip>
    </TooltipProvider>
  );
}
